.body {
    margin-left: 7rem; 
    margin-right: -5rem;
    margin-top: 2rem;
    
}

.bodyp1 {
    margin-left: 7rem; 
    margin-right: -5rem;
    margin-top: 0.5rem;
}

.bodyp4 {
    margin-left: 7rem; 
    margin-right: -5rem;
    margin-top: 3rem;
}

.img {
    margin-left: 5rem;
}